.footer{
  color: #fff;
  text-align: center;
}

.footer p{
  text-align: left;
}

.footer span{
  float: right;
  text-align: right;
}

.footer a{
  color:#fff;
}
.footer a:hover{
  color:#fff;
  font-weight: bold;
}

.link-email{
  margin-left:10px;
}