
.logo-spgrill{
  cursor:pointer;
}


.slidehome{
  /* background-color:#4c1313; */
  background-color:#222;
  padding:20px, 0px;
/*   
  border-top: 1px dashed rgb(129, 129, 129);
  border-bottom: 1px dashed rgb(129, 129, 129); */
}
.tituloprincipal{
  font-family: 'Smooch', cursive;
  font-size: 3rem;
}

.subtituloprincipal{
  font-family: 'Josefin Sans', sans-serif;
}

.font-josefin{
  font-family: 'Josefin Sans', sans-serif;
}

.item-spgrill{
  color:#fff;
  font-weight: bold;
  cursor: pointer;
  padding-top: 40px;
  display: flex;
  justify-content:space-around;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 3px;
}

input {
  width: 100%;
  margin-bottom: 15px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid #ddd;
  font-size: 15px;
  color: #444;
  transition: border-color 0.2s;
}

input:focus {
  border-color: #111;
  border: 3px solid #ddd;
}

.formlogin{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  vertical-align: middle;
  margin-top:10%;
  text-align: center;
  color:#fff;
  border: 1px solid #444;
  padding: 50px;
  max-width: 600px;
}

.btn-enviar {
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #4DC247;
  color:#fff;
  border-width: 1px;
  border-color: #4DC247;
}
.btn-enviar:hover {
  background-color: #34992e;
  color:#fff;
}


.list-orders{
  border-bottom: 1px solid #555;
  margin-bottom: 10px
}


.text-scroll{
  max-height: 650px;
  overflow-y: scroll;
  overflow-x: hidden;
}



::-webkit-scrollbar{
  width: 8px;
}
::-webkit-scrollbar-track{
  background: #222;
}
::-webkit-scrollbar-thumb{
  background: #5e5e5e;
}


.gallery{
  margin-top: 30px;
  margin-bottom: 100px;
}

.galeria {
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
}

.galeria > img{
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  border: 5px solid rgb(17, 17, 17);
  transition: all 1s;

  animation: slideup 1s forwards;
  -moz-animation: slideup 1s forwards;
  -webkit-animation: slideup 1s forwards;
  -o-animation: slideup 1s forwards;
}

.galeria img:hover{
  scale: 0.95;
}

.galeria > .img-1x1 {
  grid-column-end: span 1;
  grid-row-end: span 1;
  aspect-ratio: 1/1;
}

.galeria > .img-2x2 {
  grid-column-end: span 2;
  grid-row-end: span 2;
  aspect-ratio: 2/2;
}

.galeria > .img-2x1 {
  grid-column-end: span 2;
  grid-row-end: span 1;
  aspect-ratio: 2/1;
}

.form-actions-update-image-gallery{
  display:flex;
  justify-content: flex-end;
}

.form-actions-update-image-gallery button{
  background-color: #fff;
  border: 2px solid #202020;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 10px;
  color: #2f2f2f;
}

.form-actions-update-image-gallery button:hover{
  background-color: rgb(225, 223, 223);
}

.img-modal{
  width:100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.img-modal img{
  max-width:100%;
  border-radius:10px;
  max-height: 550px;
  overflow:hidden;
  object-fit: cover;
}

.bordermodalshow{
  border-radius: 20px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}

.bordermodalshow img{
  scale: 1.1;
  border-radius: 20px;
  overflow: hidden;
}

.dropzone {
  background: #f1f7f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  border: 1px dashed #019db0;
  padding: 8px;
  cursor: pointer;
}


.dropzone p {
  margin-top: 20px;
  align-self: center;
  text-align: center;
}

.thumbs{
  display:flex;
  justify-content: center;
  width: 100%;
}

