.tituloslide{
  font-family: 'Josefin Sans', sans-serif;
  text-shadow: 2px 2px 2px rgb(43, 43, 43);
}

.subtituloslide{
  font-family: 'Josefin Sans', sans-serif;
  text-shadow: 2px 2px 2px rgb(43, 43, 43);
}

.botasombra{
  box-shadow: 1px 1px 10px 0px rgb(16, 15, 15);
}

/* https://html-css-js.com/css/generator/text-shadow/ */